* {
  padding: 0;
  margin: 0;
}

.monty-hall-main {
  margin: auto;
  width: 85%;
  padding: 10px;
}

.doors {
  display:flex;
}

.doors img {
  width: 30%;
}

.doors img:hover {
  filter: saturate(300%) brightness(70%) hue-rotate(3deg);
}